import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export const useAuthToken = () => {
  const { getAccessTokenSilently, logout } = useAuth0();

  const getAuthToken = useCallback(async () => {
    try {
      // First attempt: Try to get the token with the cache enabled
      const token = await getAccessTokenSilently({ timeoutInSeconds: 1.5 });
      return token;
    } catch (error) {
      try {
        // Second attempt: Retry with cache disabled
        const token = await getAccessTokenSilently({ cacheMode: 'off', timeoutInSeconds: 1.5 });
        return token;
      } catch (error) {
        logout({
          logoutParams: { returnTo: window.location.origin + "/auth" }
        });

        return ""; // Just to make the function return a string
      }
    }
  }, [getAccessTokenSilently, logout]);

  return { getAuthToken };
};
